import { WsEnviaDados } from "./websocket";
import { nomesTabelas } from "./tabelas";
import db from "@/plugins/dexie";

export default async function sincronizaLocal() {
    console.log('ZZZZ');
    const aAtualizar = nomesTabelas.filter((item) => {
        return ['zzzzzzzzzzzzzz']
        .includes(item.tabela);
    });

    const post = [];
    const put = [];

    for (const item of aAtualizar) {
        const tabela = await db[item.tabela].toArray();
        
        for (const entidade of tabela) {
            if ( entidade.laadataatualizacao >  entidade.laadatanuvem) {
                put.push( entidade)
            } else if (!entidade.laadatanuvem) {
                post.push( entidade)
            }
        }
    }
    console.log()
    await WsEnviaDados({ tipo: 'postLancamentoAtividade', msg: post });
    await WsEnviaDados({ tipo: 'updateLancamentoAtividade', msg: put });
}