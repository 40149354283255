import { nomesTabelas } from "../../tabelas"
import { prefixos } from "../../tabelas";
import db from "@/plugins/dexie";
import { WsEnviaDados } from "../../websocket";
import { WSModel } from "../../wsmodels";

export default {
    enviaDataAtt: async ({ data }) => {
        const token = JSON.parse(sessionStorage.getItem('auth:token'));
        const aAtualizar = nomesTabelas.filter((item) => {
            return ![
                // NAO IGNORAR, TRAZER SOMENTE COM ID DO USUARIO É IGUAL
                'visitatecnica',
                'movimentacaoabastecimento',
                'notafiscal']
            .includes(item.tabela);
        });
        const datasAtt = await Promise.all(aAtualizar.map(async (item) => {
            if (item.tabela === 'logexclusao') {
                const table = await db.logexclusao.toArray();
                return { adeletar: table, tabela: item.tabela };
            }
            const dataatt = await db[item.tabela]
                .orderBy(`${prefixos[item.tabela].prefixo}dataatualizacao`)
                .reverse()
                .first();
            if (dataatt) {
                return { att: dataatt[`${prefixos[item.tabela].prefixo}dataatualizacao`], userId: token.userId, tabela: item.tabela };
            }
            return { att: '', userId: token.userId, tabela: item.tabela };

        }));
        console.log(datasAtt);
        if (datasAtt.length > 0) {
            WsEnviaDados({ tipo: 'verificadataatt', msg: datasAtt, connectionId: data })
        }
    },
    sincronizaLocal: async () => {
        const aAtualizar = nomesTabelas.filter((item) => {
            return ['zzzzzzz']
            .includes(item.tabela);
        });

        const post = [];
        const put = [];
    
        for (const item of aAtualizar) {
            const tabela = await db[item.tabela].toArray();
            
            for (const entidade of tabela) {
                if ( entidade.laadataatualizacao >  entidade.laadatanuvem) {
                    put.push( entidade)
                } else if (!entidade.laadatanuvem) {
                    post.push( entidade)
                }
            }
        }
        await WsEnviaDados({ tipo: 'postLancamentoAtividade', msg: post });
        await WsEnviaDados({ tipo: 'updateLancamentoAtividade', msg: put });
    },
    updategeral: async ({ data }) => {
        data.forEach(async (alteracao) => {
            // Procurar alguma forma de trazer apenas uma propriedade
            const itensTabelalocal = await db[alteracao.tabela].toArray();
            alteracao.entities.forEach((entity) => {
                if (alteracao.tabela === 'logexclusao') {
                const exclusao = db[entity.loetabela].where(
                    `${prefixos[entity.loetabela].prefixo}idnuvem`,
                ).equals(entity.loeidnuvem).delete();
                exclusao.then((deletou) => {
                    if (deletou) {
                        db.logexclusao.clear();
                        db.logexclusao.add(WSModel.logexclusao(entity));
                    }
                });
                } else {
                const campoIdNuvem = `${prefixos[alteracao.tabela].prefixo}idnuvem`;
                let existe = false;
                itensTabelalocal.forEach((itemTabelaLocal) => {
                    if (itemTabelaLocal[campoIdNuvem] === entity.id) {
                        existe = itemTabelaLocal.id;
                    }
                });
                setTimeout(() => {
                    if (existe) {
                        db[alteracao.tabela].update(existe, WSModel[alteracao.tabela](entity));
                    } else {
                        db[alteracao.tabela].add(WSModel[alteracao.tabela](entity));
                    }
                    }, 0);
                }
            });
        });
        setTimeout(async () => {
            const aAtualizar = nomesTabelas.filter((item) => {
                return ['lancamentoatividade']
                .includes(item.tabela);
            });

            const post = [];
            const put = [];
        
            for (const item of aAtualizar) {
                const updates = await db[item.tabela].toArray();
                
                for (const update of updates) {
                    if (update.laadataatualizacao > update.laadatanuvem) {
                        put.push(update)
                    } else if (!update.laadatanuvem) {
                        post.push(update)
                    }
                }
            }
            await WsEnviaDados({ tipo: 'postLancamentoAtividade', msg: post });
            await WsEnviaDados({ tipo: 'updateLancamentoAtividade', msg: put });
        }, 1000);
    }
}